@import '@assets/styles/global.scss';

.title {
  @include subtitle1;

  border-bottom: 1px solid $light-10;
  color: #FFFFFF;
  padding: 16px;
  text-align: center;
}

.wrapper {
  padding: 16px;

  .info {
    @include subtitle5;

    color: $c-light-grey;
    line-height: 1.5;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }

  .help {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 24px;

    .tg {
      align-items: center;
      background-color: #299FDA;
      color: #FFFFFF;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      transition: all 0.2s linear;
      height: 38px;
      padding-right: 8px;
      padding-left: 8px;

      &:hover {
        background-color: #244971;
      }
    }

    .phone {
      align-items: center;
      background-color: #3E3C37;
      border-radius: 8px;
      color: #FFFFFF;
      display: flex;
      gap: 8px;
      justify-content: center;
      height: 38px;
      transition: all 0.2s linear;
      padding-right: 8px;
      padding-left: 8px;

      &:hover {
        background-color: #4a2c6f;
      }
    }
  }
}

@media (max-width: 1024px) {}