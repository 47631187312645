@import '@assets/styles/global.scss';

.fix {
  background-color: red;
  font-weight: 700;
  padding: 4px;
  text-align: center;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
}

.container {
  @include subtitle4m;

  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  padding-top: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding-bottom: 12px;
  position: relative;

  .content {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: 0 auto;
  }

  .left {
    align-items: center;
    display: flex;
    gap: 40px;

    .pmPass {
      display: none;
      font-size: 20px;
      font-weight: 600;
      color: #ffffff;
    }

    .nav {
      align-items: center;
      color: #ffffff;
      display: flex;
      gap: 24px;
      

      .selected {
        color: #EAD40C;
      }
    }
  }

  .right {
    display: flex;
    gap: 32px;

    .lang {
      @include subtitle5;

      align-items: center;
      color: #ffffff;
      gap: 4px;
      display: flex;

      .langItem {
        cursor: pointer;
        transition: all .2s linear;
      }
    }

    .yellow {
      color: #EAD40C;
    }

    .loginBtn {
      display: block;
    }

    .login {
      display: none;
      align-items: center;
      gap: 6px;
      color: #C2BDB2;
      font-size: 16px;
      font-weight: 600;
    }

    .btn {
      @include subtitle6m;

      border-radius: 8px;
      cursor: pointer;
      padding: 10px 22px;
    }

    .btns {
      align-items: center;
      display: flex;
      gap: 24px;
    }

    .auth {
      @include subtitle4m;

      align-items: center;
      color: #C2BDB2;
      display: flex;
      font-size: 14px;
      font-weight: 600;
      gap: 20px;

      .points_row {
        display: flex;
        gap: 5px;
      }

      .points {
        align-items: center;
        display: flex;
        gap: 4px;
      }

      .pointsValue {
        font-size: 16px;
        font-weight: 600;
        color: #FAF9F8;
        text-align: center;
      }

      .line {
        background-color: #FFFFFF26;
        height: 32px;
        width: 1px;
      }

      .profile {
        align-items: center;
        color: #FFFFFF;
        display: flex;
        gap: 8px;

        .profileImg {
          width: 48px;
        }
      }
    }

    .enter {
      background-color: #333217;
      color: #EAD40C;
    }
  }

  .hoverY {
    @include hoverYellow;
    font-family: "Open Sans SemiBold";
    font-size: 20px;
    letter-spacing: -0.02em;
    color: #dad6ce;

    &.active {
      color: #F2F740;
    }
  }
}

@media (max-width: 1100px) {
  .container {
    
    .content {
      .left {
        .pmPass {
          display: block;
        }

        .nav {
          display: none;
        }
      }

      .right {
        .login {
          display: flex;
        }

        .loginBtn {
          display: none;
        }
      
        .auth {

          .line,
          .profile {
            display: none;
          }
        }
      }
    }
  }
}

.langDropdown {
  width: auto;
}