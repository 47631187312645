@import "../../../assets/styles/global.scss";

.container {
  padding-top: 100px;
  max-width: 85%;
  margin: 0 auto;

  .wrapper {
    @include subtitle5;

    color: #e0e0e0;
    position: relative;
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .intro_pic {
      max-width: 50%; 
      height: auto; 
      object-fit: contain; 
      flex-shrink: 0; 
    }

    .title {
      font-size: 48px;
      font-weight: 700;
      font-family: "Open Sans Bold";
      color: #faf9f8;
      width: 603px;
      .yellow {
        color: #F2F740;
      }
    }

    .info {
      line-height: 1.15;
      margin-top: 20px;
      width: 603px;

      .txt {
        font-family: "Open Sans Regular";
        font-size: 18px;
        letter-spacing: -0.02em;
        color: #c2bdb2;
      }
    }

    .btn {
      font-family: "Open Sans SemiBold";
      font-size: 16px;
      border-radius: 12px;
      background: #f2f740;
      text-transform: capitalize;
      color: #292621;
      cursor: pointer;
      min-width: 80px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      padding: 8px;

      &:hover {
        background-color: #f5f966;
      }

      &:active {
        background-color: #e6eb3d;
      }
    }
  }
}

@media (max-width: 850px) {
  .container {
    padding-top: 30px;

    .wrapper {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      .intro_pic {
        align-self: center;
        max-width: 100%; 
        width: auto; 
        height: auto;
        object-fit: contain;
        margin-top: 40px;
      }

      .title {
        font-size: 24px;
        width: 100%;
      }

      .info {
        font-size: 16px;
        width: 100%;
      }

      .btn {
        width: 100%;
        max-width: 84px;

        button {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}

