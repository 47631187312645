@import "../../../assets/styles/global.scss";

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 65vh;
  max-width: 85%;
  margin: 0 auto;
  padding-top: 100px;

  .wrapper {
    max-width: 612px;
    width: 100%;

    .trade {
      background-color: #222121;
      border-radius: 12px;
      .trade_grid {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        border-radius: 12px 12px 0 0;
        padding: 16px;
        .back {
          @include subtitle6m;

          align-items: center;
          color: #ead40c;
          cursor: pointer;
          display: flex;
          gap: 16px;
          max-width: 612px;
        }
        .title {
          font-size: 24px;
          color: #faf9f8;
          text-align: center;
          font-family: "Open Sans SemiBold";
        }
      }

      .info {
        text-align: center;
        font-size: 16px;
        color: #c2bdb2;
        font-family: "Open Sans SemiBold";
        margin-top: 10px;
        padding-left: 16px;
        padding-right: 16px;
      }

      .inputWrapper {
        color: #fff;
        font-size: 14px;
        font-family: "Open Sans SemiBold";
        margin-top: 10px;
        margin-left: 16px;
        margin-right: 16px;
        .input {
          align-items: center;
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 11px;
          display: flex;
          height: 42px;
          margin-top: 12px;
          padding: 8px 12px;
          input {
            @include body1;

            background-color: unset;
            border: none;
            color: $c-light-grey;
            outline: none;
            width: 100%;

            &::placeholder {
              color: rgba(255, 255, 255, 0.5);
            }
          }
        }

        .error {
          border: 1px solid #f44336;
        }
      }

      .btns {
        font-size: 14px;
        font-family: "Open Sans SemiBold";
        align-items: center;
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        padding-left: 16px;
        padding-right: 16px;
        .getTrade {
          cursor: pointer;
        }
        .btns_group {
          display: flex;
          gap: 16px;
          .btn {
            button {
              font-family: "Open Sans SemiBold";
              font-size: 14px;
              border-radius: 12px;
              background: #f2f740;
              text-transform: capitalize;
              color: #292621;
              cursor: pointer;
              width: 102px;
              height: 38px;
              display: flex;
              justify-content: center;
              align-items: center;

              &:hover {
                background-color: #f5f966;
              }
              &:active {
                background-color: #e6eb3d;
              }
            }
          }
          .skipBtn {
            button {
              font-family: "Open Sans SemiBold";
              font-size: 14px;
              border-radius: 12px;
              background: #3e3c37;
              text-transform: capitalize;
              color: #fff;
              cursor: pointer;
              width: 97px;
              height: 38px;
              display: flex;
              justify-content: center;
              align-items: center;

              &:hover {
                background-color: #51504b;
              }
            }
          }
        }
      }

      .skipInfo {
        font-family: "Open Sans Regular";
        font-size: 14px;
        color: #c2bdb2;
        margin-top: 35px;
        text-align: center;
        padding-right: 16px;
        padding-left: 16px;
        padding-bottom: 16px;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 850px) {
  .container {
    height: unset;
    padding-bottom: 100px;
    padding-top: 30px;

    .wrapper {

      .trade {
        padding: 20px 14px;
        .skipInfo {
          margin-top: 16px;
          font-size: 14px;
        }
        .title {
        }

        .info {
          font-size: 14px;

          width: 100%;
        }

        .btns {
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: baseline;
          .getTrade {
            font-size: 14px;
          }
          .btns_group {
            place-self: end;
          }
          .btn {
            button {
              @include subtitle7m;

              height: 36px;
              padding: 6px 16px;
              width: unset;
            }
          }
        }
      }
    }
  }
}
