.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: #222121;
  border-radius: 12px;
  width: 30%;
  position: relative;

  .close {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .back {
    position: absolute;
    left: 16px;
    top: 16px;
  }
}

@media (max-width: 1024px) {
  .modalContent {
    border-radius: 0;
    height: 100%;
    width: 100%;
    overflow: scroll;
    padding-top: 70px;

    .close {
      top: 60px;
    }
  }
}