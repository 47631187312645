@import "@assets/styles/global.scss";

.logo {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 70px;
}

.footer {
  height: 100px;
}