@import '@assets/styles/global.scss';

.title {
  @include subtitle1;

  border-bottom: 1px solid $light-10;
  color: #FFFFFF;
  padding: 16px;
  text-align: center;
}

.wrapper {
  padding: 16px;

  .errorMessage {
    @include body2;

    color: $text-error;
    margin-bottom: 16px;
    text-align: center;
  }

  .row {
    @include subtitle4;

    color: $text-white;
    text-align: center;
    margin-bottom: 16px;

    span {
      color: $control-primary;
      cursor: pointer;
    }
  }

  .rulesWrapper {
    @include body2;

    color: $text-body;
    text-align: center;

    .rules {
      color: $control-green;
      cursor: pointer;
    }
  }

  .condition {
    color: #C2BDB2;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 16px;
    .conditionItem {
      margin-left: 8px;
      position: relative;

      &::before {
        background-color: #C2BDB2;
        height: 2px;
        width: 2px;
        border-radius: 50%;
        content: '';
        position: absolute;
        top: 50%;
        right: calc(100% + 8px);
        transform: translateY(-50%);
      }

      &.errorTxt {
        color: $text-error;
        &::before {
          background-color: $text-error;
        }
      }
    }
  }

  .offer {
    background-color: #FFFFFF1A;
    border: 1px solid #FFFFFF1A;
    border-radius: 8px;
    color: #DAD6CE;
    font-size: 14px;
    font-weight: 400;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      color: $control-green;
      cursor: pointer;
    }

    .offerIcon {
      background-color: #FFFFFF80;
      border-radius: 2px;
      cursor: pointer;
      min-height: 16px;
      min-width: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (max-width: 1024px) {}