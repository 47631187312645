@import "@assets/styles/global.scss";

.wrapper {
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;

  .left {
    width: 50%;

    img {
      width: 80%;
    }
  }

  .right {
    width: 50%;

    .title {
      color: #FAF9F8;
      font-size: 48px;
      font-weight: 700;
    }

    .description {
      color: #DAD6CE;
      font-size: 20px;
      font-weight: 400;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 1100px) {
  .wrapper {
    flex-direction: column-reverse;

    .right {
      width: 100%;
      text-align: center;

      .title {
        font-size: 32px;
        font-weight: 700;
      }
    }

    .left {
      margin-top: 32px;
      width: 100%;
      text-align: center;
    }
  }
}

