@font-face {
  font-family: "Open Sans SemiBold";
  src: url("./OpenSans-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Open Sans Bold";
  src: url("./OpenSans-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Open Sans Regular";
  src: url("./OpenSans-Regular.ttf");
  font-weight: 400;
}
