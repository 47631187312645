.social {
  align-items: center;
  display: flex;
  gap: 24px;

  .socialItem {
    path {
      transition: all 0.2s linear;
    }
  }

  .socialItem:hover path {
    fill: #ead40c;
  }
}