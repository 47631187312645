$c-white: #ffffff;

$c-black: #1B1E23;
$c-light-black: #242424;

$c-dark-grey: rgba(36, 36, 36, .3);
$c-light-grey: #E0E0E0;
$c-light-grey-lines: rgba(220, 220, 220, .1);

$c-yellow: #F2F740;
$c-light-yellow: #FFEB31;
$c-dark-yellow: #333217;
$c-dark-yellow-focus: #494711;

$c-blue-main: #0CDCEA;
$c-light-blue: #69F6FF;
$c-dark-blue-btns: #193B41;
$c-dark-blue-hover: #0A5360;

$c-red: #F44336;
$c-dark-red: #351C1D;

$c-green: #07EF9C;
$c-dark-green: #11362C;

$c-blue: #1D92FF;
$c-dark-blue: #19334F;

$c-parimatch: #F1F813;
$c-parimatch-light: rgb(241, 248, 19, .15);

$c-dota: #BF2E1A;
$c-dota-light: rgba(191, 46, 26, .15);

// New colors

$icon-main: #EAD40C;
$icon-secondary: #7B756B;
$icon-chevron: #BBB5AA;
$icon-hover: #575249;
$icon-pressed: #575249;
$icon-white: #ffffff;
$icon-notification: #FF3333;
$icon-warning: #E2B603;
$icon-deposit: #009E69;

$light-10: rgba(255, 255, 255, .1);
$light-15: rgba(255, 255, 255, .15);
$light-30: rgba(255, 255, 255, .3);
$light-45: rgba(255, 255, 255, .45);
$light-50: rgba(255, 255, 255, .5);
$light-60: rgba(255, 255, 255, .6);

$background-main: #171716;
$background-secondary: #292621;
$background-snackbar: #000000;
$background-input: #3E3C37;

$control-primary: #F2F740;
$control-hover: #F5F966;
$control-pressed: #E6EB3D;
$control-green: #009E69;
$control-green-hover: #33B187;
$control-green-pressed: #009664;
$control-dark: #3E3C37;
$control-dark-hover: #51504B;
$control-dark-pressed: #3B3934;
$control-light: #FFFFFF;
$control-light-hover: #E5E5E5;
$control-light-pressed: #D9D9D9;
$control-light-toggle: #B7B7B7;

$text-title: #FAF9F8;
$text-subtitle: #DAD6CE;
$text-body: #C2BDB2;
$text-link: #00D68F;
$text-error: #FF3333;
$text-warning: #E2B603;
$text-white: #FFFFFF;
$text-dark: #292621;