@import '../../assets/styles/global.scss';

.container {
  margin-top: 24px;
  margin-bottom: 64px;

  .title {
    display: flex;
    align-items: center;

    .titleItem {
      font-size: 20px;
      font-weight: 600;
      color: #FFFFFF;
      padding: 8px 12px;
      background-color: #FFFFFF1A;
      border-radius: 12px;
    }
  }

  .table {
    margin-top: 24px;
    width: 100%;
    border: none;
    outline: none;
    border-collapse: separate;
    border-spacing: 0;
    overflow: hidden;
    
    thead tr:first-child th:first-child {
      border-top-left-radius: 9px;
      border: 1px solid #FFFFFF1A;
      border-right: none;
    }
    
    thead tr:first-child th:last-child {
      border-top-right-radius: 9px;
      border: 1px solid #FFFFFF1A;
      border-left: none;
    }

     
    thead tr:first-child th:nth-child(2) {
      border: 1px solid #FFFFFF1A;
      border-left: none;
      border-right: none;
    }

    thead tr:first-child th:nth-child(3) {
      border: 1px solid #FFFFFF1A;
      border-left: none;
      border-right: none;
    }
    
    tbody tr:last-child td:first-child {
      border-bottom-left-radius: 9px;
    }
    
    tbody tr:last-child td:last-child {
      border-bottom-right-radius: 9px;
    }
    
    thead {
     

      .head {
        font-size: 16px;
        font-weight: 600;
        background-color: #171716;
        color: #DAD6CE;
        height: 60px;
        

        .headItem {
          padding: 16px 20px;
          text-align: left;
          font-weight: 600;
        }

        .headerCenter {
          text-align: center;
        }
      }
    }

    .body {
      background-color: #222121;
      border: 1px solid #FFFFFF1A;
      border-top: none;

      .bodyItem {
        @include body1;
        color: $c-white;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        &:last-child {
          border-bottom: none;
        }

        td {
          padding: 16px 20px;
        }

        .bodyItemName {
          text-align: left;
        }

        .status {
          display: flex;

          div {
            font-size: 12px;
            font-weight: 600;
            background-color: #009E691A;
            border-radius: 100px;
            color: #009E69;
            display: inline-flex;
            height: 36px;
            padding: 4px 6px;
            align-items: center;
            justify-content: center;
          }

          .failed {
            background-color: #FF33331A;
            color: #FF3333;
            position: relative;
          }

          .send {
            background-color: #2F8FC71A;
            color: #2F8FC7;
          }

          .error {
            background-color: unset;
            height: unset;
            padding: 0;
            left: calc(100% + 8px);
            position: absolute;
            width: unset;

            img {
              cursor: pointer;
            }

            .info {
              background-color: #47451E;
              border-radius: 8px;
              bottom: calc(100% + 21px);
              display: block;
              height: unset;
              left: 50%;
              padding: 12px;
              transform: translateX(-50%);
              width: 334px;
              position: absolute;
              z-index: 10;

              .link {
                color: #EAD40C;
              }

              .infoTitle {
                @include subtitle6m;
                color: #EAD40C;
                background-color: unset;
                display: block;
                height: unset;
                padding: 0;
                position: relative;
              }

              .infoTxt {
                background-color: unset;
                border-bottom: 1px solid rgba(220, 220, 220, .1);
                border-radius: 0;
                color: #E0E0E0;
                display: block;
                font-size: 12px;
                font-weight: 400;
                height: unset;
                margin-top: 8px;
                padding: 0;
                padding-bottom: 8px;
                position: relative;
              }

              .accept {
                @include buttonsmall;
                background-color: #EAD40C;
                border-radius: 8px;
                color: #1B1E23;
                cursor: pointer;
                margin-top: 8px;
                padding: 6px 10px;
              }

              .carret {
                background-color: unset;
                border: 0;
                padding: 0;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
                top: 88%;
              }
            }
          }
        }
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }
}

@media (max-width: 850px) {
  .container {
    .table {
      thead {
        .head {
          .headItem {
            @include subtitle7m;
            justify-content: center;
            padding-left: unset;
            text-align: center;
          }
        }
      }

      .body {
        .bodyItem {
          @include tableinput;

          td {
            padding: 16px 8px;
            text-align: center;
          }

          .bodyItemName {
            padding-left: 8px;
          }

          .status {
            @include buttonmedium;
            display: flex;
            justify-content: center;

            .failed {
              padding: 6px 8px;
            }

            .error {
              left: calc(100% + 4px);

              .info {
                left: unset;
                right: 0;
                transform: unset;

                .carret {
                  left: 90%;
                  transform: unset;
                }
              }
            }
          }
        }
      }
    }
  }
}