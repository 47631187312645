@import '@assets/styles/global.scss';

.title {
  @include subtitle1;

  border-bottom: 1px solid $light-10;
  color: #FFFFFF;
  padding: 16px;
  text-align: center;
}

.wrapper {
  padding: 16px;

  .errorMessage {
    @include body2;

    color: $text-error;
    margin-bottom: 16px;
    text-align: center;
  }

  .row {
    @include subtitle4;

    color: $text-white;
    text-align: center;
    margin-bottom: 16px;

    span {
      color: $control-primary;
      cursor: pointer;
    }

    .link {
      color: $control-primary;
      cursor: pointer;
    }
  }

  .rulesWrapper {
    @include body2;

    color: $text-body;
    text-align: center;

    .rules {
      color: $control-green;
      cursor: pointer;
    }
  }
}


@media (max-width: 1024px) {
  
}