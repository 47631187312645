.wrapper {

  .title {
    font-size: 32px;
    font-weight: 700;
    color: #FAF9F8;
  }

  .subtitle {
    font-size: 20px;
    font-weight: 400;
    color: #C2BDB2;
    margin-top: 12px;
    margin-bottom: 25px;
  }

  .rules {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .rulesItem {
      background: #222121;
      border-radius: 12px;
      padding: 16px;
      width: 33%;
      min-height: 300px;

      .rulesItemImage {
        margin-bottom: 12px;
      }

      .rulesItemTitle {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 600;
        color: #FAF9F8;
      }

      .rulesItemDescription {
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 16px;
        color: #C2BDB2;
      }
    }
  }
}

@media (max-width: 1100px) {
  .wrapper {
    .title {}

    .subtitle {}

    .rules {
      display: block;

      .rulesItem {
        margin-bottom: 16px;
        width: 100%;

        .rulesItemImage {
          img {
            width: 100px;
          }
        }

        .rulesItemTitle {}

        .rulesItemDescription {}
      }
    }
  }
}

@media (max-width: 600px) {
  .wrapper {
    .title {
      font-size: 28px;
    }

    .subtitle {
      font-size: 18px;
    }

    .rules {

      .rulesItem {
      

        .rulesItemImage {}

        .rulesItemTitle {}

        .rulesItemDescription {}
      }
    }
  }
}