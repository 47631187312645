.wrapper {
  width: 85%;
  margin: 0 auto;
  padding-bottom: 50px;

  .swiperSlide {
    height: auto !important;
  }

  .banner {
    display: flex;
    justify-content: space-between;

    .left {
      align-items: center;
      display: flex;
      width: 50%;
      font-size: 56px;
      font-weight: 700;
      color: #FAF9F8;
    }

    .right {
      display: flex;
      justify-content: flex-end;
      width: 50%;
      margin-top: 30px;
      margin-bottom: 30px;

      img {
        width: 100%;
      }
    }
  }

  .missionItem {
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    gap: 15px;

    padding: 16px 12px;
    height: 100%;

    .missionItemInfo {

      .missionItemTitle {
        font-size: 24px;
        font-weight: 600;
        color: #FAF9F8;
      }

      .missionItemDescription {
        font-size: 16px;
        font-weight: 400;
        color: #C2BDB2;
        margin-top: 16px;
      }
    }

    .missionItemImage {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mission {
    display: flex;
    justify-content: space-between;
    gap: 16px;


  }

  .tabs {
    display: flex;
    gap: 16px;
    margin-top: 32px;
    margin-bottom: 32px;

    .tab {
      background-color: #FFFFFF1A;
      border-radius: 100px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      padding: 10px 12px;

      &.active {
        background-color: #FFFFFF;
        color: #292621;
      }
    }
  }
}

@media (max-width: 1100px) {
  .wrapper {
    padding-top: 80px;

    .banner {
      display: block;

      .left {
        display: block;
        width: 100%;
        text-align: center;
      }

      .right {
        display: block;
        width: unset;
        text-align: center;
      }
    }

    .missionItem {
      width: 100%;

      .missionItemInfo {
        .missionItemTitle {
          font-size: 16px;
        }

        .missionItemDescription {
          font-size: 14px;
        }
      }

      .missionItemImage {

        img {
          width: 78px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .wrapper {

    .banner {

      .left {
        font-size: 32px;
      }

      .right {}
    }

    .tabs {


      .tab {
        font-size: 14px;
      }
    }
  }
}