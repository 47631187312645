@import "../../assets/styles/global.scss";

.container {
  padding-top: 124px;
  padding-bottom: 125px;
  max-width: 600px;

  .wrapper {
    align-items: center;
    background-color: #222121;
    border-radius: 8px;
    justify-content: center;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-top: 35px;

    .content {
      text-align: center;

      .title {

        border-bottom: 1px solid #FFFFFF26;

        color: $c-white;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 24px;
        padding-bottom: 16px;
      }

      .info {
        @include subtitle5;

        color: $c-light-grey;
        line-height: 1.5;
        font-size: 16px;
        font-weight: 400;
      }

      .help {
        display: flex;
        gap: 20px;
        justify-content: center;
        margin-top: 24px;

        .tg {
          align-items: center;
          background-color: #299FDA;
          color: #FFFFFF;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          transition: all 0.2s linear;
          height: 38px;
          padding-right: 8px;
          padding-left: 8px;

          &:hover {
            background-color: #244971;
          }
        }

        .phone {
          align-items: center;
          background-color: #3E3C37;
          border-radius: 8px;
          color: #FFFFFF;
          display: flex;
          gap: 8px;
          justify-content: center;
          height: 38px;
          transition: all 0.2s linear;
          width: 100px;

          &:hover {
            background-color: #4a2c6f;
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .container {
    padding-top: 75px;
    width: 90%;

    .wrapper {
      .content {}
    }
  }
}

@media (max-width: 850px) {
  .container {
    .wrapper {
      padding-bottom: 20px;
      padding-top: 20px;

      .content {}
    }
  }
}

@media (max-width: 600px) {
  .container {
    .wrapper {
      .content {
        padding-left: 34px;
        padding-right: 34px;
        width: 100%;

        .title {
          @include h3;
        }

        .info {
          @include body1;
        }
      }
    }
  }
}