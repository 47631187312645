@import "../../assets/styles/global.scss";

.wrapper {
  position: relative;
  margin-top: 60px;

  .container {
    width: 85%;
    margin: 0 auto;
  
    .section {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .title {
        font-weight: 700;
        color: #ffffff;
        font-family: "Open Sans Bold";
        font-size: 48px;
        letter-spacing: -0.01em;
        color: #faf9f8;
      }
      .group {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-top: 20px;
        .group_1 {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 15px;

          .pic1 {
            box-shadow: 0px 4px 6.8px 0px rgba(19, 22, 28, 1);
            background: url("../../assets/images/home_product_1.png");
            background-repeat: no-repeat;
            background-size: 100%;
            width: 418px;
            height: 185px;
            border-radius: 12px;
            .text {
              margin-top: 16px;
              display: flex;
              flex-direction: column;
              gap: 4px;
              padding: 0 16px;
              .name {
                font-size: 16px;
                letter-spacing: -0.02em;
                color: #dad6ce;
              }
              .desc {
                font-family: "Open Sans Bold";
                font-weight: 600;
                font-size: 20px;
                letter-spacing: -0.02em;
                color: #faf9f8;
                display: flex;
                align-items: center;
                gap: 5px;
              }
            }
          }
          .pic2 {
            box-shadow: 0px 4px 6.8px 0px rgba(19, 22, 28, 1);
            background: url("../../assets/images/home_product_2.png");
            background-repeat: no-repeat;
            background-size: 100%;
            width: 418px;
            height: 185px;
            border-radius: 8px;
            .text {
              margin-top: 16px;
              display: flex;
              flex-direction: column;
              gap: 4px;
              padding: 0 16px;
              .name {
                font-size: 16px;
                letter-spacing: -0.02em;
                color: #dad6ce;
              }
              .desc {
                font-family: "Open Sans Bold";
                font-weight: 600;
                font-size: 20px;
                letter-spacing: -0.02em;
                color: #faf9f8;
                display: flex;
                align-items: center;
                gap: 5px;
              }
            }
          }
          .pic3 {
            box-shadow: 0px 4px 6.8px 0px rgba(19, 22, 28, 1);
            background: url("../../assets/images/home_product_3.png");
            background-repeat: no-repeat;
            background-size: 100%;
            width: 418px;
            height: 185px;
            border-radius: 8px;
            .text {
              margin-top: 16px;
              display: flex;
              flex-direction: column;
              gap: 4px;
              padding: 0 16px;
              .name {
                font-size: 16px;
                letter-spacing: -0.02em;
                color: #dad6ce;
              }
              .desc {
                font-family: "Open Sans Bold";
                font-weight: 600;
                font-size: 20px;
                letter-spacing: -0.02em;
                color: #faf9f8;
                display: flex;
                align-items: center;
                gap: 5px;
              }
            }
          }
          .pic4 {
            box-shadow: 0px 4px 6.8px 0px rgba(19, 22, 28, 1);
            background: url("../../assets/images/home_product_4.png");
            background-repeat: no-repeat;
            background-size: 100%;
            width: 418px;
            height: 185px;
            border-radius: 8px;
            .text {
              margin-top: 16px;
              display: flex;
              flex-direction: column;
              gap: 4px;
              padding: 0 16px;
              .name {
                font-size: 16px;
                letter-spacing: -0.02em;
                color: #dad6ce;
              }
              .desc {
                font-family: "Open Sans Bold";
                font-weight: 600;
                font-size: 20px;
                letter-spacing: -0.02em;
                color: #faf9f8;
                display: flex;
                align-items: center;
                gap: 5px;
              }
            }
          }
        }
        .group_2 {
          background-image: url("../../assets/images/bg_section_2_desktop.png");
          background-repeat: no-repeat;
          background-position: bottom;
          background-size: cover;
          border-radius: 8px;
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: row;
          gap: 50px;
          align-items: center;
          justify-content: space-between;
          .group_2_img {
            height: 100%;
            max-width: 526px;
          }
          .group_2_img_mob {
            display: none;
          }
          .desc_group {
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: baseline;
            margin-left: 40px;
            .btn {
              font-weight: 500;
              font-style: normal;
              border-radius: 12px;
              width: 104px;
              height: 42px;
              background: #f2f740;
              line-height: 130%;
              color: #292621;
              font-size: 16px;
              cursor: pointer;
              &:hover {
                background-color: #f5f966;
              }

              &:active {
                background-color: #e6eb3d;
              }
            }
            .desc_title {
              font-family: "Open Sans Bold";
              font-weight: 700;
              font-size: 56px;
              letter-spacing: 0em;
              color: #faf9f8;
              line-height: 1;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .wrapper {
    .container {
      .section {
        .group {
          .group_1 {
            .pic1 {
              border-radius: 12px;
              width: 298px;
              height: 185px;
            }
            .pic2 {
              border-radius: 12px;
              width: 298px;
              height: 185px;
            }
            .pic3 {
              border-radius: 12px;
              width: 298px;
              height: 185px;
            }
            .pic4 {
              border-radius: 12px;
              width: 298px;
              height: 185px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1266px) {
  .wrapper {
    .container {
      .section {
        .group {
          .group_1 {
            .pic1 {
              border-radius: 12px;
              width: 298px;
              height: 185px;
              .text {
                .name {
                  font-size: 14px;
                }
                .desc {
                  font-size: 16px;
                }
              }
            }
            .pic2 {
              border-radius: 12px;
              width: 298px;
              height: 185px;
              .text {
                .name {
                  font-size: 14px;
                }
                .desc {
                  font-size: 16px;
                }
              }
            }
            .pic3 {
              border-radius: 12px;
              width: 298px;
              height: 185px;
              .text {
                .name {
                  font-size: 14px;
                }
                .desc {
                  font-size: 16px;
                }
              }
            }
            .pic4 {
              border-radius: 12px;
              width: 298px;
              height: 185px;
              .text {
                .name {
                  font-size: 14px;
                }
                .desc {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1160px) {
  .wrapper {
    .container {
      .section {
        .group {
          .group_2 {
            background: linear-gradient(180deg, #171716 0%, #8f00ff 400%);
            display: flex;
            flex-direction: column;
            align-items: center;
            .group_2_img {
              display: none;
            }
            .group_2_img_mob {
              display: block;
              max-width: 326px;
              
            }
            .desc_group {
              align-items: center;

              .desc_title {
                font-weight: 700;
                font-size: 32px;
                text-align: center;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .wrapper {
    .container {
      .section {
        .title {
          margin-left: 0px;
        }
        .group {
          align-items: center;

          .group_1 {
            margin-left: 0px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
          }
          .group_2 {
            margin-left: 0px;
            .desc_group {
              margin-left: 0px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 679px) {
  .wrapper {
    .container {
      .section {
        width: 100%;
       .title {
        font-weight: 400;
        font-size: 32px;
        line-height: 100%;
        letter-spacing: 0.03em;
        
       }
      }
    }
  }
}
@media (max-width: 619px) {
  .wrapper {
    .container {
      .section {
        .group {
          .group_1 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            .pic1,
            .pic2,
            .pic3,
            .pic4 {
              background-size: cover;
              background-position: right;
              width: 160px;
              height: 164px;
              .text {
                .name {
                  font-size: 10px;
                }
                .desc {
                  font-size: 11px;
                }
              }
            }
          }
          .group_2 {
            background: linear-gradient(180deg, #171716 0%, #8f00ff 400%);
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            width: 100%;
            min-width: 375px;
            .group_2_img {
              display: none;
            }
            .group_2_img_mob {
              display: block;
              
            }
            .desc_group {
              align-items: center;
              gap: 20px;
              .desc_title {
                font-weight: 700;
                font-size: 32px;
                text-align: center;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 407px) {
  .wrapper {
    .container {
      .section {
       .title {
       
        font-size: 32px;
        
       }
      }
    }
  }
}
