@import "../../../assets/styles/global.scss";

.container {
  padding-top: 100px;
  max-width: 85%;
  margin: 0 auto;

  .wrapper {
    @include subtitle5;

    color: #e0e0e0;
    position: relative;
    width: 100%;
    z-index: 2;

    .wrapper_group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 80px;

      .wrapper_group_one {
        .title {
          font-size: 32px;
          font-family: "Open Sans Bold";
          line-height: 1.2em;
          color: #faf9f8;
          width: 730px;

          .yellow {
            color: #f2f740;
          }
        }

        .info {
          line-height: 115%;
          margin-top: 20px;
          width: 150%;
          display: flex;
          flex-direction: column;
          gap: 24px;
          justify-content: space-between;

          .group_one,
          .group_two {
            display: flex;
            flex-direction: column;
            gap: 14px;
            align-items: center;
            width: 48%;
            align-items: baseline;

            img {
              width: 100px;
              height: 100px;
              object-fit: cover;
              border-radius: 8px;
            }

            .obj_one,
            .obj_two {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .title {
                font-family: "Open Sans SemiBold";
                font-size: 20px;
                color: #dad6ce;
                line-height: 1.2em;
              }

              .desc {
                font-family: "Open Sans Regular";
                font-size: 16px;
                color: #c2bdb2;
                line-height: 1.1em;
              }
            }
          }
        }

        .banner {
          display: none;
        }

        .button_group {
          display: flex;
          flex-direction: row;
          gap: 16px;

          .btn {
            font-family: "Open Sans SemiBold";
            font-size: 16px;
            border-radius: 12px;
            background: #f2f740;
            text-transform: capitalize;
            color: #292621;
            cursor: pointer;
            width: 80px;
            height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;

            &:hover {
              background-color: #f5f966;
            }

            &:active {
              background-color: #e6eb3d;
            }
          }

          .btn_back {
            font-family: "Open Sans SemiBold";
            font-size: 16px;
            border-radius: 12px;
            background: #3e3c37;
            text-transform: capitalize;
            color: #fff;
            cursor: pointer;
            width: 64px;
            height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;

            &:hover {
              background-color: #51504b;
            }
          }
        }
      }

      .wrapper_group_two {
        .intro_pic {
          height: auto;
          object-fit: contain;
          flex-shrink: 0;
        }
      }
    }

  }
}

@media (max-width: 850px) {
  .container {
    padding-top: 30px;

    .backgroundImg {
      display: none;
    }

    .wrapper {
      width: 100%;

      .wrapper_group {
        display: flex;
        flex-direction: column;

        .wrapper_group_one {
          .title {
            font-size: 24px;
            width: 100%;
          }


          .info {
            font-size: 16px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;

            .group_one,
            .group_two {
              width: 100%;
              margin-bottom: 24px;
              flex-direction: column;
              align-items: baseline;


              img {
                width: 80px;
                height: 80px;
              }

            }
          }
        }

      }

      .wrapper_group_two {
        .intro_pic {
          max-width: 100%;
        }
      }

      .back {
        margin-bottom: 24px;
      }

      .banner {
        display: block;

        img {
          width: 100%;
        }
      }
    }
  }
}