@font-face {
    font-family: 'HelveticaNeue';
    src: url('./HelveticaNeueCyr-Roman.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('./HelveticaNeueCyr-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('./HelveticaNeueCyr-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'HelveticaNeueBlackCondensed';
    src: url('./HelveticaNeueBlackCondensed.ttf') format('truetype');
    font-weight: 900; 
    font-style: normal;
  }