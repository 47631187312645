@import "@assets/styles/global.scss";

.wrapper {
  margin-top: 20px;
  padding-bottom: 50px;
  position: relative;

  .container {
    .social {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .left {
        align-items: center;
        display: flex;
        gap: 24px;
      }

      .right_mob {
        display: none;
      }
    }

    .license_offer {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
    }

    .license {
      @include helper;
      color: rgba(255, 255, 255, 0.45);
      text-align: center;
    }

    .offer {
      @include helper;
      color: #009E69;
      text-align: center;
      cursor: pointer;
    }
  }
}

@media (max-width: 850px) {
  .wrapper {
    margin-top: 20px;

    .container {
      .social {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .left {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          width: auto;
          justify-items: center;
          grid-gap: 12px;
        }
        .right_mob {
          align-items: center;
          display: flex;
          gap: 24px;
          width: 273px;
          place-content: end;

          .socialItem {
            path {
              transition: all 0.2s linear;
            }
          }

          .socialItem:hover path {
            fill: #F2F740;
          }
        }
        .right {
          margin: 24px auto;
          width: 300px;
          display: none;
        }
      }
    }
  }
}
