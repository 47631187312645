@import '../../assets/styles/global.scss';

.match {
  background-color: #222121;
  border-radius: 8px;

  .title {
    @include subtitle1b;

    align-items: center;
    border-bottom: 1px solid #DCDCDC26;
    color: #fff;
    display: flex;
    gap: 8px;
    padding: 16px;
  }

  .main {
    padding: 16px;

    .txt {
      font-size: 16px;
      font-weight: 400;

      align-items: center;
      color: #EAD40C;
      display: flex;
      gap: 10px;
    }

    .info {
      @include body1;

      color: #C2BDB2;
      line-height: 130%;
      margin-top: 20px;

      .infoItem {
        margin-bottom: 8px;
      }

      span {
        font-weight: 500;
      }
    }
  }

  .success {
    @include subtitle5;

    align-items: center;
    color: #FFFFFF;
    display: flex;
    gap: 8px;
    padding: 16px;
  }
}

@media (max-width: 850px) {
  .match {
    margin-bottom: 24px;
    width: 100%;

    .title {
      @include subtitle3b;
    }
  }
}