@import "../../assets/styles/global.scss";

.container {
  padding-top: 32px;
  padding-bottom: 240px;
  width: 85%;
  margin: 0 auto;
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .wrapper_inner_first_group {
      width: 100%;
      display: flex;
      .wrapper_inner_item {
        background: url("../../assets/images/winter_season_banner.png");
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        border-radius: 8px;
        max-width: 1720px;
        width: 100%;
        min-height: 96px;
        .wrapper_inner_item_text {
          font-size: 24px;
          color: #ffffff;
          padding: 0 12px;
        }
      }
    }
    .wrapper_inner_second_group {
      display: flex;
      flex-direction: row;
      gap: 16px;
      width: 100%;
      .wrapper_inner_item {
        display: flex;
        border-radius: 12px;
        max-width: 332px;
        width: 100%;
        height: 112px;
        padding: 12px 12px;

        &:nth-child(1) {
          background: url("../../assets/images/main_banner_first.png");
          background-position: right;
          background-repeat: no-repeat;
          background-size: cover;
        }
        &:nth-child(2) {
          background: url("../../assets/images/main_banner_second.png");
          background-position: right;
          background-repeat: no-repeat;
          background-size: cover;
        }
        &:nth-child(3) {
          background: url("../../assets/images/main_banner_third.png");
          background-position: right;
          background-repeat: no-repeat;
          background-size: cover;
        }
        &:nth-child(4) {
          background: url("../../assets/images/cards/cyber.png");
          background-position: right;
          background-repeat: no-repeat;
          background-size: cover;
        }
        &:nth-child(5) {
          background: url("../../assets/images/main_banner_fourth.png");
          background-position: right;
          background-repeat: no-repeat;
          background-size: cover;
        }
        &:nth-child(6) {
          background: url("../../assets/images/main_banner_fifth.png");
          background-position: right;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .wrapper_inner_text_group {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .wrapper_inner_text_group_first {
            font-family: "Open Sans SemiBold";
            color: #dad6ce;
            font-size: 16px;
          }
          .wrapper_inner_text_group_second {
            display: flex;
            align-items: center;
            gap: 5px;
            font-family: "Open Sans SemiBold";
            font-size: 13px;
            color: #ffffff;
          }
        }
      }
    }
  }
}
@media (max-width: 1440px) {
  .container {
    .wrapper {
      .wrapper_inner_first_group {
        .wrapper_inner_item {
          background-position: right;
        }
      }
      .wrapper_inner_second_group {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto;
      }
    }
  }
}
@media (max-width: 1100px) {
  .container {
    padding-top: 80px;
  }
}
@media (max-width: 1000px) {
  .container {
    .wrapper {
      .wrapper_inner_second_group {
        display: grid;
        grid-template-columns: repeat(3, 2fr);
        grid-template-rows: auto;
      }
    }
  }
}
@media (max-width: 850px) {
  .container {
    .wrapper {
      .wrapper_inner_first_group {
        .wrapper_inner_item {
          .wrapper_inner_item_text {
            font-size: 20px;
          }
        }
      }
    }
  }
}
@media (max-width: 620px) {
  .container {
    padding-bottom: 50px;
    .wrapper {
      .wrapper_inner_first_group {
        .wrapper_inner_item {
          background: url("../../assets/images/winter_banner_mob.png");
          background-position: left;
          background-size: cover;
          background-repeat: no-repeat;
          .wrapper_inner_item_text {
            font-size: 20px;
          }
        }
      }
      .wrapper_inner_second_group {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        gap: 16px;

        .wrapper_inner_item:last-child {
          grid-column: 1 / -1;
          max-width: none;
        }
      }
    }
  }
}
@media (max-width: 502px) {
  .container {
    .wrapper {
      .wrapper_inner_first_group {
        .wrapper_inner_item {
          .wrapper_inner_item_text {
            font-size: 15px;
          }
        }
      }
      .wrapper_inner_second_group {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        gap: 16px;
        justify-items: center;

        .wrapper_inner_item:last-child {
          grid-column: 1 / -1;
          max-width: none;
        }
        .wrapper_inner_item {
          width: 100%;
          &:nth-child(1) {
            background: url("../../assets/images/main_banner_first_mob.png");
            background-position: right;
            background-repeat: no-repeat;
            background-size: cover;
          }
          &:nth-child(2) {
            background: url("../../assets/images/main_banner_second_mob.png");
            background-position: right;
            background-repeat: no-repeat;
            background-size: cover;
          }
          &:nth-child(3) {
            background: url("../../assets/images/main_banner_third_mob.png");
            background-position: right;
            background-repeat: no-repeat;
            background-size: cover;
          }
          &:nth-child(4) {
            background: url("../../assets/images/cards/cyber_mob.png");
            background-position: right;
            background-repeat: no-repeat;
            background-size: cover;
          }
          &:nth-child(5) {
            background: url("../../assets/images/main_banner_fourth_mob.png");
            background-position: right;
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
    }
  }
}
