.wrapper {
  position: relative;
  padding-top: 32px;

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-bottom: 16px;
  }

  .links {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    margin-top: 24px;
  }
}

@media (max-width: 1100px) {
 .wrapper {

  .row {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .links {
    display: block;
  }
 }
}