@import '@assets/styles/global.scss';

.title {
  @include subtitle1;

  border-bottom: 1px solid $light-10;
  color: #FFFFFF;
  padding: 16px;
  text-align: center;
}

.wrapper {
  padding: 16px;
  color: #DAD6CE;

  .subtitle {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 6px;
  }

  .list {
    list-style: disc;
    margin-bottom: 6px;

    .listItem {
      list-style: disc;
      margin-left: 15px;
    }
  }
}

@media (max-width: 1024px) {}