@import '@assets/styles/global.scss';

.title {
  @include subtitle1;

  border-bottom: 1px solid $light-10;
  color: #FFFFFF;
  padding: 16px;
}

.image {
  padding: 12px;
  img {
    width: 100%;
  }
}

.subtitle {
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF;
  padding: 12px 16px;
}

.text {
  padding-left: 16px;
  .textItem {
    font-weight: 400;
    font-size: 16px;
    color: #DAD6CE;
    margin-bottom: 4px;
  }
}

.button {
  display: flex;
  justify-content: end;
  padding: 16px;
}

@media (max-width: 1024px) {}