@import "../../assets/styles/global.scss";

.wrapper {
  position: relative;

  .container {
    width: 85%;
    margin: 0 auto;

    .section {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .title {
        font-weight: 700;
        color: #ffffff;
        font-family: "Open Sans Bold";
        font-size: 48px;
        letter-spacing: -0.01em;
        color: #faf9f8;
      }

      .group {
        background-image: url("../../assets/images/bg_section_desktop.png");
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 24px;
        width: 100%;
        max-height: 520px;
        height: 100%;
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        place-self: center;
        background-size: cover;
        background-position-x: left;
        background-position-y: top;
        padding-left: 40px;
        .img {
          max-width: 675px;
          max-height: 525px;
          width: 100%;
          height: 100%;
          
        }
        .img_mob {
          display: none;
        }

        .desc {
          display: flex;
          flex-direction: column;
          align-items: baseline;
          .button {
            @include subtitle4m;

            border-radius: 12px;
            width: 104px;
            height: 42px;
            background: #f2f740;
            margin-top: 30px;
            line-height: 130%;
            text-transform: capitalize;
            color: #292621;
            font-size: 16px;
            cursor: pointer;
            &:hover {
              background-color: #f5f966;
            }

            &:active {
              background-color: #e6eb3d;
            }
          }

          .title {
            @include h2;
            font-size: 56px;
            letter-spacing: 0em;
            color: #faf9f8;
          }

          .subtitles {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin-top: 30px;

            .subtitle {
              font-weight: 500;
              font-size: 14px;
              letter-spacing: -0.02em;
              color: #fff;
              border-radius: 100px;
              width: auto;
              height: 31px;
              background: rgba(255, 255, 255, 0.1);
              display: flex;
              align-items: center;
              padding: 10px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1688px) {
  .wrapper {
    .container {
      .section {
        .group {
          background-image: url("../../assets/images/bg_section_desktop_mid.png");
          // padding-left: 100px;
        }
      }
    }
  }
}
@media (max-width: 1306px) {
  .wrapper {
    .container {
      .section {
        .group {
          
          .img {
            
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .wrapper {
    .container {
      .section {
        .title {
          margin-left: 0px;
        }
        .group {
          margin-left: 0px;
          height: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 15px;
          background: linear-gradient(
            180deg,
            #1c191b 0%,
            #371f2e 50%,
            #604326 100%
          );

          .img {
            display: none;
          }
          .img_mob {
            display: block;
            object-fit: cover;
            max-width: 300px;
            max-height: 267px;
            height: 100%;
            width: 100%;
          }
          .desc {
            padding-left: 0;
            display: flex;
            flex-direction: column;
            gap: 0px;
            align-items: center;
            .button {
              font-size: 16px;
            }

            .title {
              font-size: 36px;
              margin-left: 0px;
              text-align: center;
            }
            .subtitles {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: 0px;
              justify-content: center;
              .subtitle {
                font-size: 14px;
                height: auto;
                padding: 8px;
                margin-top: 0px;
                gap: 0px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 999px) {
  .wrapper {
    .container {
      .section {
        .group {
          
          .img {
          }
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .wrapper {
    .container {
      width: 100%;

      .section {

        .title {
          width: 85%;
          margin: 0 auto;
        }
        .group {
          padding-left: 0;
          overflow: hidden;
          .desc {
            padding-top: 40px;
            .title {
              margin: 0 auto;
            }
            padding-left: 0px;
            .subtitles {
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
              margin-left: 10px;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 679px) {
  .wrapper {
    margin-top: 40px;
    .container {

      .section {
        width: 100%;
        .title {
          font-weight: 400;
          font-size: 32px;
          line-height: 100%;
          letter-spacing: 0.03em;
        }

      }
    }
  }
}
