@import '@assets/styles/global.scss';

.wrapper {

  .container {

    .title {
      @include h2;

      color: #ffffff;
    }

    .account {
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 8px;

      .mobEdit {
        display: none;
      }

      .header {
        align-items: center;
        border-bottom: 1px solid #DCDCDC1A;
        display: flex;
        justify-content: space-between;
        padding: 16px;

        .headerLeft {
          @include subtitle1b;

          align-items: center;
          color: #ffffff;
          display: flex;
          gap: 8px;
        }

        .headerRight {
         cursor: pointer;
        }
      }

      .main {
        padding: 16px;

        .profileImg {
          width: 68px;
        }

        .name {
          align-items: center;
          color: #ffffff;
          display: flex;
          gap: 5px;
          font-size: 20px;
          font-weight: 400;
          color: #DAD6CE;
          margin-bottom: 6px;

          .id {
            color: #FFFFFF;
            font-weight: 400;
          }

          .copy {
            cursor: pointer;
          }
        }

        .address {
          font-size: 16px;
          font-weight: 400;
          color: #C2BDB2;
          margin-bottom: 9px;
        }

        .infoItem {
          font-size: 16px;
          font-weight: 600;

          color: #DAD6CE;
          margin-bottom: 12px;

          .infoItemTitle {
            display: flex;
            gap: 4px;
            margin-bottom: 5px;
          }

          .steam {
            display: flex;
            gap: 4px;
            margin-bottom: 8px;

            .copy {
              cursor: pointer;
            }
          }
        }
      }

      .info {
        display: flex;
        gap: 80px;


      }
    }
  }
}

@media (max-width: 850px) {
  .wrapper {

    .container {
      padding-top: 50px;

      .title {
        @include h3;
      }

      .account {

        .mobEdit {
          display: flex;
          justify-content: end;
          margin-bottom: 12px;
        }

        .header {

          .headerLeft {
            @include subtitle3b;
          }
        }

        .main {
          .profileImg {
            width: 64px;
          }

          .name {
            @include subtitle6m;
          }
        }

        .info {
          display: block;
          margin-top: 24px;

          .infoItem {
            @include body1;

            margin-bottom: 12px;
          }
        }
      }
    }
  }
}