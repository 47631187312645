@import "../../assets/styles/global.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #222121;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 8px;
  transition: all 0.2s linear;
  height: 100%;
  max-width: 418px;
  width: 100%;

  .imgWrapper {
    align-items: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    height: 120px;
    justify-content: center;
    transition: transform 0.5s;
    margin-top: 20px;
  }

  .chestTitleWrapper {
    border-bottom: 1px solid rgba(220, 220, 220, 0.1);
    width: 100%;
    margin-top: 18px;
    padding: 6px 16px;

    .chestTitle {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .chestName {
        font-family: "Open Sans SemiBold";
        font-size: 20px;
        display: flex;
        gap: 6px;
        align-items: center;
        color: #faf9f8;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        justify-content: space-between;

        .chestName_left {
          display: flex;
          gap: 6px;
        }

        .chestName_right {
          border-radius: 16px;
          padding: 4px 4px;
          background: rgba(255, 255, 255, 0.1);
          font-size: 12px;
          font-family: "Open Sans SemiBold";
          color: #fff;
        }
      }
    }
  }

  img {
    width: 100%;
  }

  .productName {
    font-family: "Open Sans SemiBold";
    font-size: 16px;
    color: #faf9f8;
    margin-bottom: 6px;
    margin-top: 8px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 16px;
  }

  .questTask {
    font-family: "Open Sans Regular";
    font-size: 14px;
    padding: 0 16px;
    color: #c2bdb2;
    margin-bottom: 8px;
    max-width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    white-space: normal;
    text-overflow: ellipsis;
    flex-grow: 1;
  }

  .productButtons {
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding: 16px 16px;
    gap: 10px;

    .readMoreBtn {
      font-family: "Open Sans Regular";
      color: #ffffff;
      font-weight: 500;
      font-size: 13px;
      border: none;
      cursor: pointer;
      display: inline-block;
    }

    .productPrice {
      @include subtitle3b;
      color: $c-yellow;
      margin-top: 24px;
    }

    .btn {
      margin-top: auto;
      place-self: end;

      button {
        font-family: "Open Sans SemiBold";
        background-color: #f2f740;
        border-radius: 12px;
        cursor: pointer;
        height: 38px;
        transition: all 0.2s linear;
        width: 73px;
        font-size: 14px;
        color: #292621;

        .loader {
          animation: spin 0.5s linear infinite;
          width: 24px;
        }

        &:hover {
          background-color: #f5f966;
        }
      }
    }
  }

  &:hover {
    border: 1px solid #ead40c;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}