@import '@assets/styles/global.scss';

.container {
  display: none;
}

@media (max-width: 1100px) {
  .container {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #171716;

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 85%;
      margin: 0 auto;

      .title {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-weight: 600;
        color: #FFFFFF73;

        .current {
          color: #FAF9F8;
        }
      }

      .arrow {
        transform: rotate(270deg);
      }
    }

    .menu {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: #171716;

      .menuContent {

        .menuItem {
          border-bottom: 1px solid rgba(255, 255, 255, 0.15);
          border-top: 1px solid rgba(255, 255, 255, 0.15);
          padding-top: 20px;
          padding-bottom: 20px;
          color: #C2BDB2;
          font-size: 14px;
          font-weight: 600;

          .menuItemContent {
            width: 85%;
            margin: 0 auto;

            &.active {
              color: #F2F740;
            }
          }
        }
      }
    }

    &.active {
      background-color: #FFFFFF26;
    }
  }
}