@import "../../../assets/styles/global.scss";

.container {
  padding-top: 100px;
  max-width: 85%;
  margin: 0 auto;

  .wrapper {
    @include subtitle5;

    color: #e0e0e0;
    position: relative;
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 48px;
      font-weight: 700;
      font-family: "Open Sans Bold";
      color: #faf9f8;
      width: 603px;
      .yellow {
        color: #f2f740;
      }
    }

    .info {
      font-family: "Open Sans Regular";
      font-size: 18px;
      letter-spacing: -0.02em;
      color: #c2bdb2;
      width: 672px;

      .txt {
        margin-top: 4px;
      }
    }
    .button_group {
      display: flex;
      flex-direction: row;
      gap: 16px;
      .btn {
        font-family: "Open Sans SemiBold";
        font-size: 16px;
        border-radius: 12px;
        background: #f2f740;
        text-transform: capitalize;
        color: #292621;
        cursor: pointer;
        min-width: 80px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        padding: 8px;

        &:hover {
          background-color: #f5f966;
        }

        &:active {
          background-color: #e6eb3d;
        }
      }
      .btn_back {
        font-family: "Open Sans SemiBold";
        font-size: 16px;
        border-radius: 12px;
        background: #3e3c37;
        text-transform: capitalize;
        color: #fff;
        cursor: pointer;
        width: 64px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        &:hover {
          background-color: #51504b;
        }
      }
    }

    .pic {
      max-width: 40%;
      height: auto;
      object-fit: contain;
      flex-shrink: 0;
    }
  }
}

@media (max-width: 850px) {
  .container {
    padding-top: 30px;

    .wrapper {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;

      .back {
        margin-bottom: 24px;
      }

      .title {
        font-size: 24px;
        width: 100%;
      }

      .info {
        font-size: 16px;
        width: 100%;
      }
      .button_group {
        .btn {
          width: 100%;
          min-width: 84px;

          button {
            width: 100%;
            justify-content: center;
          }
        }
        .btn_back {
          width: 100%;
          min-width: 84px;
          button {
            width: 100%;
            justify-content: center;
          }
        }
      }
      .pic {
        align-self: center;
        max-width: 100%;
        width: auto;
        height: auto;
        object-fit: contain;
        margin-top: 40px;
      }
    }
  }
}

.rockets_subtitle {
  font-weight: 700;
  color: #faf9f8;
  margin-top: 4px;
}

.rockets_txt {
  color: #c2bdb2;
  margin-top: 4px;
}