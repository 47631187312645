.wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 12px;
  padding: 100px 32px;
  background-position: center right;

  .title {
    color: #FAF9F8;
    font-size: 48px;
    font-weight: 700;
  }

  .description {
    color: #DAD6CE;
    font-size: 20px;
    font-weight: 400;
    margin-top: 24px;
    width: 50%;
    margin-bottom: 24px;
  }
}

.another {
  margin-top: 32px;

  .anotherTitle {
    font-size: 32px;
    font-weight: 700;
    color: #FAF9F8;
  }

  .anotherSubtitle {
    font-size: 20px;
    font-weight: 400;
    color: #C2BDB2;
    margin-top: 12px;
    margin-bottom: 25px;
  }

  .anotherItems {
    display: flex;
    gap: 16px;
    justify-content: space-between;

    .anotherItem {
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 12px;
      padding: 17px;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .anotherItemInfo {

        .anotherItemTitle {
          font-size: 24px;
          font-weight: 600;
          color: #FAF9F8;
        }

        .anotherItemDescription {
          font-size: 18px;
          font-weight: 400;
          color: #C2BDB2;
          margin-top: 12px;
        }
      }

      .anotherItemImage {
        img {
          width: 100px;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .another {
    .anotherTitle {
      font-size: 28px;
    }

    .anotherSubtitle {
      font-size: 18px;
    }

    .anotherItems {
      flex-direction: column;

      .anotherItem {
        width: 100%;
      }
    }
  }
}

@media (max-width: 800px) {
  .wrapper {
    background-position: center center;
    padding: 24px 16px;

    .title {
      font-size: 22px;
    }

    .description {
      font-size: 16px;
      width: unset;
    }
  }

  .another {
    .anotherTitle {
    }

    .anotherSubtitle {
    }

    .anotherItems {

      .anotherItem {
        flex-direction: column;
        gap: 24px;
      }
    }
  }
}