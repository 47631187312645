@import '@assets/styles/global.scss';

.title {
  @include subtitle1;

  border-bottom: 1px solid $light-10;
  color: #FFFFFF;
  padding: 16px;
  text-align: center;

  .titleText {
    width: 90%;
    margin: 0 auto;
  }
}

.wrapper {
  padding: 16px;
}

.message {
  color: #DAD6CE;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
}

.buttons {
  display: flex;
  justify-content: end;
  gap: 16px;
}

.modalImage {
  width: 84px;
  height: 84px;
}
.modalContent {
  background: #222121;
  border-radius: 12px;
  max-width: 540px;
  width: 90%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
.modalContent_top_button {
  padding: 16px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  .modalContent_top_button_header {
    margin: auto;
    text-align: center;
    color: #faf9f8;
    font-size: 24px;
    font-family: "Open Sans SemiBold";
  }
  .modalContent_top_button_x {
    cursor: pointer;
  }
}
.modal_inner {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  gap: 6px;
  .modal_inner_price {
    border-radius: 100px;
    background: rgba(255, 255, 255, 0.1);
    .modal_inner_price_p {
      font-family: "Open Sans SemiBold";
      display: flex;
      justify-content: center;
      font-weight: 400;
      font-size: 14px;
      color: #fff;
      width: auto;
      height: auto;
      padding: 6px 8px;
    }
  }
  .modal_inner_name {
    .modal_inner_name_n {
      @include body2;
      font-weight: 700;
      font-size: 20px;
      color: #ffffff;
    }
  }
  .modal_inner_desc {
    .modal_inner_desc_d {
      font-family: "Open Sans Regular";
      font-size: 14px;
      color: #c2bdb2;
      line-height: 1.5em;
    }
  }
}
.modal_inner_buttons {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: end;
  align-items: center;

  .modal_inner_buttons_take {
    background: #f2f740;
    border-radius: 12px;
    width: 73px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .modal_inner_buttons_take_t {
      font-family: "Open Sans SemiBold";
      font-size: 14px;
      color: #292621;
      cursor: pointer;

      .btn {
        cursor: pointer;
      }
    }
  }
}
.modal_inner_buttons_cancel {
  display: flex;
  justify-content: end;
  .modal_inner_buttons_cancel_c {
    display: flex;
    justify-content: end;
    cursor: pointer;
  }
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 1024px) {}