@import "../../assets/styles/global.scss";

.wrapper {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;

  .container {
    background-color: #222121;
    border-radius: 12px;
    color: #dad6ce;
    max-width: 612px;
    width: 90%;
    padding: 20px;

    .title {
      font-size: 24px;
      color: #faf9f8;
      text-align: center;
      font-family: "Open Sans SemiBold";
      margin-bottom: 24px;
    }

    .subtitle {
      font-family: "Open Sans Regular";
      font-size: 16px;
      margin-bottom: 16px;
    }

    .list {
      .listItem {
        display: flex;
        align-items: center;
        font-family: "Open Sans Regular";
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.02em;
        color: #c2bdb2;
        margin-bottom: 8px;
        padding-left: 5px;
        position: relative;
        padding-left: 16px;
        margin-left: 16px;
        span {
          color: #ffffff;
          font-weight: 500;
        }

        .listImage {
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .listItem_down {
      font-family: "Open Sans Regular";
      font-weight: 400;
      font-size: 16px;
      letter-spacing: -0.02em;
      color: #c2bdb2;
    }

    .btn {
      display: flex;
      justify-content: end;
      margin-top: 20px;
      button {
        font-family: "Open Sans SemiBold";
        font-size: 16px;
        border-radius: 12px;
        background: #f2f740;
        text-transform: capitalize;
        color: #292621;
        cursor: pointer;
        width: 76px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: #f5f966;
        }
        &:active {
          background-color: #e6eb3d;
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .wrapper {
    .container {
      .subtitle {
        font-size: 16px;
      }

      .list {
        .listItem {
          font-size: 14px;
        }
      }
    }
  }
}
