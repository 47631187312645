@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Bold.ttf');
    font-weight: 700;
}
