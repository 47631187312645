@import "@assets/styles/global.scss";

.wrapper {
  position: relative;
  // background-image: url("../../assets/images/prefooter_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 50px;
  .section {
    margin-top: 60px;
    width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .group {
      max-width: 550px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      gap: 20px;
  
      .title {
        font-family: "Open Sans Bold";
        font-weight: 800;
        font-size: 56px;
        letter-spacing: 0.02em;
        text-align: center;
        color: #faf9f8;
        white-space: nowrap;
      }
      .desc_group {
        display: flex;
        flex-direction: column;

        .desc {
          font-family: "Open Sans SemiBold";
          font-weight: 700;
          font-size: 20px;
          color: #dad6ce;
        }
      }

      .btn {
        font-weight: 500;
        font-style: normal;
        border-radius: 12px;
        width: 104px;
        height: 42px;
        background: #f2f740;
        margin-top: 20px;
        line-height: 130%;
        color: #292621;
        font-size: 16px;
        cursor: pointer;
        &:hover {
          background-color: #f5f966;
        }

        &:active {
          background-color: #e6eb3d;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .wrapper {
    .section {
      .group_img {
      }
      .group {
        margin-left: 0px;
      }
    }
  }
}
@media (max-width: 679px) {
  .wrapper {
    // background: linear-gradient(
    //   180deg,
    //   rgba(97, 0, 255, 0) 0%,
    //   rgba(97, 0, 255, 0.3) 100%
    // );
    .section {
      display: flex;
      flex-direction: column;
      .group_img {
        width: 100%;
        margin-top: 25px;
      }
      .group {
        align-items: center;
        .title {
          font-size: 32px;
        }
        .desc_group {
          text-align: center;
          .desc {
            font-size: 16px;
          }
        }
        .btn {
          width: 104px;
          height: 42px;
        }
      }
    }
  }
}
