@import '@assets/styles/global.scss';

.title {
  @include subtitle1;

  border-bottom: 1px solid $light-10;
  color: #FFFFFF;
  padding: 16px;
  text-align: center;
}

.wrapper {
  padding: 16px;
  max-height: 60vh;
  overflow: hidden;
  overflow-y: auto;
  font-size: 16px;
  font-weight: 400;
  color: #DAD6CE;
  
  // Кастомный скроллбар
  &::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(32, 30, 27, 0.5);
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #C2BDB2;
    border-radius: 3px;
    
    &:hover {
      background-color: rgba(242, 247, 64, 0.9);
    }
  }
  
  &::-webkit-scrollbar-track {
    background-color: rgba(64, 62, 57, 0.3);
    border-radius: 3px;
  }
  
  // Для Firefox
  scrollbar-width: thin;
  scrollbar-color: rgba(242, 247, 64, 0.7) rgba(64, 62, 57, 0.3);

  .offer {
    @include body2;

    color: $text-body;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    max-height: unset;
  }
}