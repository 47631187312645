@import "../../../assets/styles/global.scss";

.wrapper {
  background-color: rgba(0, 0, 0, .5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  .container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #242424;
    // height: 50%;
    border-radius: 16px 16px 0 0;
    padding: 24px 16px 4px 16px;

    .title {
      @include subtitle4m;

      color: #fff;
      margin-bottom: 24px;
      text-align: center;
    }

    .sort {
      @include subtitle6m;

      color: $c-light-grey;
      margin-bottom: 16px;

      .filterWrapper {
        display: flex;
        gap: 12px;
        margin-top: 12px;

        .filterItem {
          background-color: #DCDCDC0D;
          border-radius: 100px;
          padding: 11px 14px;
        }

        .selectedFilter {
          background-color: $c-dark-yellow;
          color: $c-yellow;
        }
      }

      .chips__container {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        margin-top: 12px;

        .chips__container_item {
          align-items: center;
          background-color: #DCDCDC0D;
          border-radius: 100px;
          display: flex;
          gap: 4px;
          padding: 11px 14px;

          img {
            display: none;
          }
        }

        .selected_chips__container_item {
          background-color: $c-dark-yellow;
          color: $c-yellow;

          img {
            display: block;
          }
        }
      }
    }

    .btns {

      .btns__wrapper {
        margin-bottom: 12px;

        button {
          width: 100%;
        }
      }
    }

    .closeBtn {
      right: 16px;
      position: absolute;
      top: 16px;
    }
  }
}