@import '@assets/styles/global.scss';

.reverse {
  flex-direction: row-reverse
}


.select {
  background-color: #DCDCDC1A;
  border-radius: 11px;
  display: flex;
  justify-content: space-between;
  height: 36px;
  margin-bottom: 20px;
  margin-top: 4px;
  padding: 1px;


  .selectItem {
    @include subtitle4;
    align-items: center;
    color: $c-light-grey;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 33%;
  }

  .selected {
    background-color: #74736F;
    border-radius: 11px;
    color: $text-white;
  }
}

@media (max-width: 850px) {
  .select {
    .selectItem {
      @include tableinput;
    }

    .selected {
      @include buttonsmall;
    }
  }
} 