.container {
  align-items: center;
  background-color: #11362C;
  color: #07EF9C;
  display: flex;
  gap: 8px;
  padding: 10px 10px;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .icon {
    height: 24px;
    width: 24px;
  }

  .message {
    width: 80%;
  }
}

.warning {
  background-color: #333217;
  color: #EAD40C;
}

.error {
  background-color: #351C1D;
  color: #F44336;
}