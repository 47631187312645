.container {
  position: relative;
  margin-top: 60px;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 16px;
    background: #000000;
    position: absolute;
    bottom: calc(100% + 20px);
    left: 0;
    border-radius: 12px;
  }

  .tournamentsWrapper {
    overflow-x: clip;
  }

  .customNavigation {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    z-index: 10;
    pointer-events: none;

    .prevButton,
    .nextButton {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      pointer-events: auto;
      margin: 0 -20px;

      svg {
        path {
          fill: #292621;
        }
      }
    }

    .nextButton {
      transform: rotate(180deg);
    }
  }

  .swiper {
    overflow: unset;
    padding-right: 10px;
  }

  .tournament {
    background: #000000;
    width: 150px;
    border-radius: 12px;
    padding-top: 16px;
    padding-left: 8px;
    padding-right: 8px;
    height: 200px;
    position: relative;

    &.active {
      box-shadow: 0px 0px 12.5px -1px rgba(248, 255, 19, 1);
      border: 1px solid #F2F740;

      .tournamentLogo {
        img {
          opacity: 1;
        }
      }

      .tournamentName {
        font-weight: 600;
        font-size: 14px;
        color: #FAF9F8;
        margin-top: 30px;
        text-align: center;
      }

      .tournamentDates {
        font-size: 14px;
        font-weight: 400;
        color: #C2BDB2;
        margin-top: 8px;
        text-align: center;
      }
    }

    &.completed {
      box-shadow: 0px 0px 12.5px -1px #009E6940;
      border: 1px solid #009E69;
    }

    .tournamentLogo {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 84px;
        width: 84px;
        opacity: 0.6;
      }
    }

    .tournamentName {
      font-weight: 600;
      font-size: 14px;
      color: #FFFFFF4D;

      margin-top: 30px;
      text-align: center;
    }

    .tournamentDates {
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF4D;
      margin-top: 8px;
      text-align: center;
      position: absolute;
      bottom: 8px;
      left: 0;
      right: 0;
    }

    .tournamentStatus {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      right: 0;
      bottom: 110%;
    }
  }
}
