@import '@assets/styles/global.scss';

.row {
  margin-bottom: 16px;

  .label {
    @include subtitle4;

    color: $text-white;
    margin-bottom: 12px;

    span {
      color: #F44336;
    }
  }

  .input {
    align-items: center;
    background-color: rgba(220, 220, 220, .1);
    border: 1px solid $light-10;
    border-radius: 11px;
    display: flex;
    justify-content: space-between;
    height: 42px;
    padding-left: 12px;
    padding-right: 12px;

    .inputWrapper {
      align-items: center;
      display: flex;
      gap: 6px;
      width: 100%;

      input {
        @include body2;
        background-color: unset;
        border: unset;
        color: $c-light-grey;
        outline: unset;
        width: 100%;

        &::placeholder {
          color: $light-50;
        }
      }
    }

    &.inputFocus {
      @include borderYellow;
    }

    &.error {
      border: 1px solid #F44336;
    }
  }
}

@media (max-width: 850px) {
  .row {
    .input {
      .inputWrapper {
        input {
          @include tableinput;
        }
      }
    }
  }
} 