@import '@assets/styles/global.scss';

.btn {
  border-radius: 12px;
  cursor: pointer;
  min-width: 87px;
  padding: 8px;

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    background-color: $light-10;
  }
}

.large {
  @include buttonLarge;
  height: 42px;
}

.medium {
  @include buttonMedium;
  height: 38px;
}

.small {
  @include buttonSmall;
  height: 34px;
}

.btn.disabled {
  background-color: $light-10;
  color: $light-45;
  cursor: not-allowed;
}

.primary {
  background-color: $control-primary;
}

.dark {
  background-color: $control-dark;
  color: #FFFFFF;
}

.light {
  background-color: $control-light;
}

.disabled {
  background-color: $control-dark;
}

.wide {
  min-width: 100%;
}