@import "../../assets/styles/global.scss";

.container {
  transition: all 0.2s linear;
  width: 100%;
  margin-bottom: 70px;
  width: 85%;
  .container__line {
    margin-top: 20px;
    border-radius: 8px;
    width: 100%;
    height: 52px;
    background-image: url("../../assets/images/line_desktop.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 0 10px;
    .title {
      font-weight: 400;
      font-size: 18px;
      line-height: 163%;
      color: #ffc785;
      padding-top: 10px;
    }
  }
  .container__line_mob {
    display: none;
  }
  .container__title {
    @include subtitle5;
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-top: 30px;
    .title {
      @include h2;

      align-items: center;
      color: #ffffff;
      display: flex;
      justify-content: space-between;
      font-size: 40px;
    }
  }
  .banner_wrapper {
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    width: 100%;
    .banner {
      margin-top: 20px;
      background-image: url("../../assets/images/banner_shop_bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      min-height: 119px;
      max-height: 60px;
      border-radius: 12px;
      display: flex;
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .banner__img {
      }
      .banner__group {
        display: flex;
        flex-direction: column;
        justify-content: end;
        gap: 5px;

        padding-left: 15px;
        padding-right: 15px;
        .banner_p_one {
          @include subtitle1b;
          font-size: 22px;
          color: #fff;
        }
        .banner_p_two {
          @include subtitle1b;
          font-size: 15px;
          color: #e8f0ff;
          font-weight: 400;
        }
        .banner__group_img {
          display: none;
        }
      }
    }
  }

  .container__filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    flex-wrap: wrap;
    gap: 10px;
    .chips {
      @include body1;
      display: flex;
      gap: 12px;
      align-items: center;
      margin-top: 30px;

      .reset_filter {
        cursor: pointer;
        white-space: nowrap;
        font-size: 14px;
        color: $c-light-grey;
        display: flex;
        gap: 4px;
        align-items: center;
        white-space: nowrap;
      }

      .chips__container {
        display: flex;
        gap: 12px;
      }
      .chips__container_item {
        background-color: rgba(255, 255, 255, 0.1);
        font-family: "Open Sans SemiBold";
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
        border-radius: 100px;
        padding: 6px 8px;

        color: #ffffff;
        cursor: pointer;
        white-space: nowrap;
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;

        img {
          display: none;
        }
      }
      .selected_chips__container_item {
        background-color: rgba(255, 255, 255, 0.3);
        color: #fff;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

        img {
          display: block;
        }
      }
      .chips__container_divider {
        color: #fff;
      }
    }

    .filter {
      font-family: "Open Sans SemiBold";
      font-size: 16px;
      align-items: center;
      background-color: #3e3c37; 
      border-radius: 12px;
      color: #fff;
      cursor: pointer;
      display: flex;
      justify-content: center;
      gap: 4px;
      width: 138px;
      height: 42px;
      position: relative;
      transition: all 0.2s linear;

      .filterWrapper {
        @include body1;

        background-color: #000000;
        border-radius: 8px;
        color: #e0e0e0;
        left: 0;
        padding: 8px 0;
        position: absolute;
        top: 120%;
        width: 170px;
        z-index: 1;

        .filterItem {
          cursor: pointer;
          margin-bottom: 3px;
          padding: 6px 16px;
          transition: all 0.2s linear;

          &:hover {
            background-color: rgba(220, 220, 220, 0.1);
          }
        }

        .selectedFilter {
          background-color: #3E3C37;
          color: #fff;
        }
      }

      // &:hover {
      //   background-color: #4a2c6f;
      // }
    }

    .filterModal {
      display: none;
    }
  }

  .mobFilter {
    display: none;
  }

  .wrapper {
    align-items: start;
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 50px;

    .loaderContainer {
      @include subtitle1b;

      color: #f9f9f9;
      display: flex;
      grid-column: span 4;
      justify-content: center;
      margin-top: 50px;

      .loaderWrapper {
        background-color: $c-white;
        border-radius: 133px;
        height: 8px;
        margin-top: 32px;
        width: 236px;

        .loader {
          background-color: #4a2c6f;
          border-radius: 133px;
          height: 100%;
          animation: loader 0.5s ease-in-out forwards;
          width: 0;
        }
      }
    }

    .empty {
      @include h3;

      color: $c-white;
      grid-column: span 4;
      margin-top: 50px;
      text-align: center;

      .emptyInfo {
        @include subtitle5;

        margin-top: 20px;

        .link {
          color: $c-yellow;
        }
      }
    }
  }

  .more {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    position: relative;
    .down_button {
      background: #3e3c37;
      border-radius: 12px;
      width: 78px;
      height: 42px;
      font-size: 16px;
      color: #fff;
      font-family: "Open Sans SemiBold";
      cursor: pointer;
    }
  }

  .up {
    align-items: center;
    background-color: #3e3c37;;
    border-radius: 100px; 
    cursor: pointer;
    display: flex;
    width: 42px;
    height: 42px;
    justify-content: center;
    position: absolute;
    right: 0;
    transition: all 0.2s linear;
    

    // &:hover {
    //   background-color: #4a2c6f;
    // }
  }

  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    
  }
}

@keyframes loader {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .container {
    .wrapper {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

@media (max-width: 1100px) {
  .container {
    .container__filter {
      align-items: flex-start;
      flex-direction: column;
      margin-top: 32px;
      gap: 16px;
      display: none;

      .chips {
        display: none;
      }

      .filterModal {
        background-color: $c-dark-yellow;
        color: $c-yellow;
        display: flex;

        &:hover {
          background-color: #494711;
        }
      }
    }

    .mobFilter {
      display: flex;
      align-items: center;
      justify-content: space-between;     
      margin-top: 20px;
      
      .mobFilter__games {
        display: flex;
        gap: 16px;

        .mobFilter__game {
          background-color: #FFFFFF1A;
          border-radius: 16px;
          color: #fff;
          padding: 6px 8px;
          display: flex;
          align-items: center;
          gap: 4px;

          &.active {
            background-color: #FFFFFF4D;
          }
        }
      }
    }
  }
}

@media (max-width: 920px) {
  .container__filter {
    .chips {
      display: flex;
      flex-direction: column;
    }
    .chips__container_divider {
      display: none;
    }
  }
}

@media (max-width: 900px) {
  .container {
    .banner_wrapper {
      .banner {
        .banner__group {
          .banner_p_one {
            font-size: 18px;
          }
          .banner_p_two {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .container {
    .banner_wrapper {
      .banner__img {
        display: none;
      }
      .banner {
        background-image: none;
        max-height: none;
        border-radius: 8px;
        background: linear-gradient(90deg, #0051ff 0%, #0a74ff 100%);
        justify-content: center;
        .banner__group {
          padding-top: 15px;
          .banner_p_one {
            font-size: 20px;
          }
          .banner_p_two {
            font-size: 14px;
          }
          .banner__group_img {
            display: block;
            width: 100%;
          }
        }
      }
    }

    padding-top: 75px;
    .container__title {
      .title {
        display: block;
        font-size: 36px;
      }
    }

    .container__filter {
      align-items: start;
      .filter {
      }
    }

    .wrapper {
      grid-template-columns: 1fr;
      margin-top: 24px;

      .empty {
        @include subtitle1b;

        .emptyInfo {
          @include body1;

          margin-top: 20px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .container {
    .container__line {
      display: none;
    }
    .container__line_mob {
      margin-top: 20px;
      display: block;
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background: rgba(255, 112, 0, 0.25);
      display: flex;
      gap: 6px;
      padding: 10px 10px;
      align-items: center;
      .mob_line {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          font-weight: 600;
          font-size: 18px;
          line-height: 168%;
          color: #fff;
        }
        .desc {
          font-family: var(--second-family);
          font-weight: 400;
          font-size: 12px;
          line-height: 173%;
          color: #ffc785;
        }
      }
    }
  }
}
