@import "@assets/styles/global.scss";

.wrapper {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;

  .info {
    .section {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      width: 85%;
      margin: 0 auto;

      .banner_pic {
        display: block;
        min-height: 620px;
        height: 100%;
        object-fit: cover;
        margin: 0 auto;
        max-width: 671px;
        width: 100%;
      }

      .banner_pic_mid {
        display: none;
      }

      .banner_pic_mob {
        display: none;
      }

      .group {
        // max-width: 820px;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        gap: 10px;

        .title {
          font-family: "Open Sans Bold";
          font-size: 48px;
          letter-spacing: 0em;
          color: #faf9f8;
          line-height: 1.1em;
          white-space: nowrap;
        }

        .title2 {
          font-family: "Open Sans Regular";
          font-size: 24px;
          font-weight: 400;
          letter-spacing: 0em;
          color: #faf9f8;
          line-height: 1.2em;
          width: 90%;
        }
        .subtitle {
          @include subtitle2m;

          color: #ffffff;
        }

        .btn {
          @include subtitle4m;
          font-size: 16px;
          border-radius: 12px;
          min-width: 104px;
          min-height: 42px;
          background: #f2f740;
          line-height: 130%;
          text-transform: capitalize;
          color: #292621;
          cursor: pointer;
          margin-top: 16px;

          &:hover {
            background-color: #f5f966;
          }

          &:active {
            background-color: #e6eb3d;
          }
        }
      }

      .banner_pic_wrapper {
        margin-top: 30px;
        width: 50%;

        display: flex;
        justify-content: end;

        img {
          width: 100%;
        }
      }
    }

    .container {
      color: #ffffff;
      z-index: 2;

      .title {
        @include h1;
        margin-bottom: 20px;
      }

      .subtitle {
        @include subtitle5;
        line-height: 120%;
        margin-bottom: 24px;
        width: 45%;
      }

      .btn {
        @include subtitle6m;
        border-radius: 8px;
        cursor: pointer;
        padding: 10px 22px;
      }

      .reg {
        background-color: #ead40c;
        color: #1b1e23;
      }

      .platforms {
        @include df-aic;
        gap: 32px;
        margin-top: 40px;
      }
    }
  }

  .banner {
    display: none;
  }
}

@media (max-width: 1589px) {
  .wrapper {
    .info {
      .section {}
    }
  }
}

@media (max-width: 1442px) {
  .wrapper {
    .info {
      .section {
        .group {
          margin-top: 40px;

          .title {
            font-size: 48px;
            letter-spacing: -0.02em;
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .wrapper {
    background-image: none !important;
    .info {
      .section {
        display: block;
        padding-top: 100px;
        
        .group {
          width: 100%;
        }

        .banner_pic_wrapper {
          width: 100%;
        }
      }

      .container {
        .title {
          width: 50%;
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .wrapper {
    display: block;
    padding-top: 65px;

    .info {
      height: unset;

      .section {
        padding-top: unset;
        .group {
          margin-left: 50px;
        }
      }

      .container {
        .title {
          @include h2;
          width: 100%;
        }

        .subtitle {
          width: 90%;
        }

        .platforms {
          img {
            width: 180px;
          }
        }
      }
    }

    .bannerDesk {
      display: none;
    }

    .banner {
      display: block;

      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 679px) {
  .wrapper {
    .info {
      .section {
        margin-left: 0px;
        margin-top: 5px;
        padding: 0px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0px;
        width: 100%;

        .banner_pic_mob {
          display: block;
          margin-top: 30px;

          width: 100%;
          height: 100%;
        }

        .banner_pic_mid {
          display: none;
        }

        .group {
          align-items: center;
          gap: 7px;
          margin-left: 0px;

          .btn {
            place-self: center;
          }

          .subtitle {
            @include subtitle5;
          }

          .title {
            font-size: 30px;
            text-align: center;
            white-space: normal;
          }

          .btn {
            font-size: 16px;
            margin-top: 16px;
          }
        }
      }

      .container {
        z-index: 0;

        .platforms {
          img {
            width: 150px;
          }
        }
      }
    }
  }
}