@import '@assets/styles/global.scss';

.title {
  @include subtitle1;

  border-bottom: 1px solid $light-10;
  color: #FFFFFF;
  padding: 16px;
  text-align: center;
}

.wrapper {
  padding: 16px;
}

.message {
  color: #DAD6CE;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
}

.buttons {
  display: flex;
  justify-content: end;
  gap: 16px;
}

@media (max-width: 1024px) {}